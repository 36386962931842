import React, { useContext } from 'react'
import { VscDebugBreakpointData } from 'react-icons/vsc';
import { GlobalDataContext } from '../../context/context'
import { ButtonContent } from '../global/boton/ButtonContent';

import imgDefault from '../../assets/image/placeholder.png'


const Block_8 = ({ title, text, listsAbout, listsServices, image1,image2,image3,image4, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='bgdegra pt-28 pb-12 md:pb-16 md:pt-32 my-[140px] lg:my-[270px]'>
            <div className='w-[90%] md:w-4/5 mx-auto block justify-center lg:flex'>
            <div className= 'border-white border-[8px] absolute bgBloque right-[3%] lg:right-[17%] lg:-mt-[25%] -mt-[48%] w-[250px] h-[250px] lg:w-[440px] lg:h-[440px] rounded-full z-10 bg-cover bg-center' style={{ backgroundImage: `url("${image1 ? image1 : imgDefault}")` }}></div>
            <div className=' border-white border-[5px] absolute bgBloque left-[5%] lg:left-[23%] -mt-[13%] lg:w-[300px] lg:h-[300px] w-[160px] h-[160px] rounded-full z-10 bg-cover bg-center' style={{ backgroundImage: `url("${image2 ? image2 : imgDefault}")` }}></div>
            <div className=' border-white border-[8px] hidden lg:flex absolute bgBloque left-[18%] mt-[30%] w-[390px] h-[390px] rounded-full z-10 bg-cover bg-center' style={{ backgroundImage: `url("${image3 ? image3 : imgDefault}")` }}></div>
            <div className=' border-white border-[5px] hidden lg:flex absolute bgBloque right-[16%] mt-[35%] w-[280px] h-[280px] rounded-full z-10 bg-cover bg-center' style={{ backgroundImage: `url("${image4 ? image4 : imgDefault}")` }}></div>
                <div className='w-full lg:w-[90%] rounded-lg text-white'>
                    <div className='text-center lg:text-center px-5 pt-28 pb-28 '>
                        {
                            sloganPrincipal ?
                                rpdata?.dbPrincipal?.licensed.length > 1 ?
                                    <h2 className='pb-10 capitalize'>
                                        {rpdata?.dbPrincipal?.licensed}
                                    </h2>
                                    : <h2 className='pb-10 capitalize'>we have {rpdata?.dbPrincipal?.exprYears} years of experience </h2>
                                :
                                <h1 className='pb-10 capitalize'>
                                    {
                                        title ? title :
                                            <span className='lowercase text-[18px]'>
                                                {`default title={'frase'}`} o
                                                {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                            </span>
                                    }
                                </h1>
                        }
                        <p className='pb-5 lg:px-24 px-1'>
                            {
                                text ? text :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>
                        {
                            listsAbout ?
                                <ul className='grid grid-cols-1 md:grid-cols-2 pb-5'>
                                    {
                                        rpdata?.dbAbout?.[0].list.length > 1 ?
                                            rpdata?.dbAbout?.[0].list.map((item, index) => {
                                                return (

                                                    <li key={index} className="py-2 flex items-center">
                                                        <VscDebugBreakpointData />
                                                        <span className="pl-2">{item}</span>
                                                    </li>
                                                )
                                            })
                                            : null
                                    }
                                </ul>
                                : null
                        }
                        {
                            listsServices ?
                                <ul className="grid grid-cols-1 md:grid-cols-2 pb-5">
                                    {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                                        return (
                                            <li key={index} className="py-1 flex items-center">
                                                <VscDebugBreakpointData />
                                                <span>{item.name}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                                : null
                        }
                        <div>
                            <ButtonContent btnphone={rpdata?.dbPrincipal?.phones[0]?.phone}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Block_8