import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Services/ServicesHome_3";
import HeroSection from "../components/Home/HeroSection";
import Paletacolor from "../components/global/Paletacolor";
import Block8 from "../components/block/Block_8";
import BlockThree from "../components/block/Block-V2";
import BlockFive from "../components/block/BlockFive";
import VideoPromocional from "../components/global/VideoPromocional";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">

        <Modal />

        <HeroSection />

        <BlockFive
          text={rpdata?.dbHome?.[0].text}
          image={rpdata?.gallery?.[4]}
          sloganPrincipal={true}
          listsServices={true}
        />

        {/* video promocional */}
        {
          rpdata?.videosPromo?.[0]?.activo ?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={rpdata?.gallery?.[1]}
            />
            : null
        }

        <Directories />

        <Block8
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image1={rpdata?.gallery?.[6]}
          image3={rpdata.gallery?.[12]}
          image2={rpdata?.stock?.[2]}
          image4={rpdata?.gallery?.[9]}
        />

        <CounterUp image={rpdata?.gallery?.[18]} />

        <BlockThree
          title={'a little about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image1={rpdata?.gallery?.[19]}
          image3={rpdata?.gallery?.[11]}
          listAbout={true}
        />
        <div className=" my-14 w-full mx-auto justify-center">
          <h2 className="text-center capitalize"> What our customers say </h2>
          <div class="elfsight-app-884b2595-b52e-4fea-b791-1b7bab7313f5"></div>
        </div>

        <ServicesHome />

        {/* Paleta de Colores */}

        {
          rpdata?.brandingExtra?.[0]?.activo ?
            <Paletacolor />
            : null
        }
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.gallery?.[6]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>

        <Map />

      </div>
    </BaseLayout>
  );
}

export default Home;
